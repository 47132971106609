


















import {Component, Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class PrivacyPolicyHeader extends Mixins(MixinScreenSize) {}
