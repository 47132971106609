































































































































































































































































































































































































































































































































































































import {Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Component} from 'vue-property-decorator'

@Component
export default class PrivacyPolicyContent extends Mixins(MixinScreenSize) {}
