












import {Component, Vue} from 'vue-property-decorator'
import AppFooter from '@/components/AppFooter.vue'
import NavbarMenu from '@/components/NavbarMenu.vue'
import {MetaInfo} from 'vue-meta'
import PrivacyPolicyHeader from '@/components/privacyPolicy/PrivacyPolicyHeader.vue'
import PrivacyPolicyContent from '@/components/privacyPolicy/PrivacyPolicyContent.vue'

@Component({
  components: {
    AppFooter,
    NavbarMenu,
    PrivacyPolicyHeader,
    PrivacyPolicyContent,
  },
})
export default class PrivacyPolicyView extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.privacyPolicy'),
    }
  }

  mounted() {
    window.scrollTo(0, 0)
  }
}
